.policy-content
{
    margin: 50px 15px;
    font-family: 'Poppins', sans-serif;

    .policy-title
    {
        font-size: 36px;
        color: #7265b8;
        direction: rtl;
    }

    .policy-description
    {
        direction: rtl;
        color: black;
        font-size: 16px;
    }

    .policy-content
    {
        direction: rtl;
        color: black;
        font-size: 16px;
        margin: 0;
        
        .content-title
        {
            font-weight: 600;
            color: #7265b8;
            margin: 30px 0;
        }

        .content-value
        {
            margin: 20px 0;
            line-height: 26px;
            color: grey;
        }
    }
}