.page-content
{
    margin: 0 auto;
}

input, button, textarea, select
{
    font-family: 'Cairo', sans-serif;
}

.input-text, .input-textarea, .input-select
{
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    color: #000;
    border: 1px solid #f1f1f1;
    background-color: #e4e4e4;
    font-size: 16px;
    border-radius: 5px 5px 5px 5px;
    outline: none;
    padding-right: 15px;
    box-sizing: border-box;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    box-sizing: border-box;
}

.input-text.error
{
    border: 2px solid red; 
}

.input-select
{
    width: 100%;
}

.input-textarea
{
    height: auto;
    resize: none;
}

.input-text:focus, .input-textarea:focus, .input-select:focus
{
    border: 1px solid grey;
}

.form-button
{
    background: #7265b8;
    border: none;
    color: #fff;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    padding: 10px 0;
    border-radius: 3px;
    border: 2px solid transparent;
    -webkit-transition: all .50s ease-in-out;
    -o-transition: all .50s ease-in-out;
    transition: all .50s ease-in-out;
}

.form-button:hover
{
    background: #fff;
    color: #7265b8;
    border: 2px solid #7265b8;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
{ 
    -webkit-appearance: none; 
     margin: 0; 
}

button:disabled
{
    background-color: white;
    border: 2px solid #2e611e;
    color: #2e611e;
}

button:disabled:hover
{
    background-color: white;
}