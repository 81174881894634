.page-content {
	.whatsapp-sticky {
		width: 100px;
		height: 100px;
		position: fixed;
		bottom: 0px;
		right: 0px;
		float: right;
		z-index: 1000;

		i {
			color: #fff;
			font-size: 50px;
			background-color: #28d268;
			padding: 7px 11px;
			border-radius: 50%;
			text-shadow: 1px 1px grey;
			box-shadow: 0px 0px 4px 1px grey;
			cursor: pointer;
			opacity: 0.7;
			transition: 0.2s ease-in-out;
		}

		i:hover {
			transform: scale(1.05);
			opacity: 1;
		}
	}

	.images-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
		padding: 0 50px;

		img {
			width: auto;
			max-height: 100%;
			overflow: hidden;
			margin: 0 5px;
		}
	}

	.content-wrapper {
		display: flex;
		flex-direction: row-reverse;
		// background-color: #7265b8;
		padding-bottom: 20px;
		margin-top: 10px;
		flex-wrap: wrap;

		.home-content {
			padding: 20px 0;
			direction: rtl;
			flex: 1;
			min-width: 300px;

			.home-title {
				text-align: center;
				font-size: 20px;
				font-weight: 600;
				color: #7265b8;
				width: fit-content;
				margin: auto;
				padding: 0px 5px;
				border-bottom: 4px solid #7265b8;
			}

			.content-div {
				margin: 0px 10%;

				.content-row {
					text-align: right;
					font-size: 16px;
					line-height: 1.2;
					color: #7c8595;
				}

				.content-val {
					display: list-item;
					text-align: right;
					font-size: 15px;
					line-height: 1.4;
					color: #7c8595;
					margin: 0;
				}
			}
		}

		.fill-data {
			background-color: #fff;
			margin: 40px 0px;
			padding: 0px 20px;
			border-radius: 5px;
			text-align: center;
			direction: rtl;
			margin: 10px;
			padding: 10px;
			min-width: 400px;
			flex: 1;
			// border: 2px solid #7c8595;

			.accept-policy-line {
				text-align: right;

				.accept-policy {
					color: blue;
				}

				.accept-policy-cb {
					zoom: 1.2;
					cursor: pointer;
				}

				.accept-policy-text {
					cursor: pointer;
				}
			}

			.form-title {
				line-height: 1.2;
				color: #7265b8;
				font-weight: 700;
				font-size: 22px;
			}

			.form-description {
				margin-bottom: 25px;
				font-size: 17px;
				line-height: 28px;
				font-weight: 400;
				color: #7c8595;
			}

			.seprated-div {
				display: flex;
				.error-message {
					color: red;
				}

				.data-info-wrapper {
					background-color: #e4e4e4;
					width: 300px;
					max-width: 100%;
					margin: auto;
					padding: 20px;
					border-radius: 15px;
					box-sizing: border-box;

					.info-row {
						display: flex;
						align-items: center;
						margin-bottom: 15px;

						.info-col.icon {
							font-size: 60px;
							margin-left: 20px;
							color: #7265b8;
						}

						.info-col {
							display: flex;
							flex-direction: column;

							.info-tile {
								font-weight: bold;
								color: #7265b8;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	.bottom-content {
		direction: rtl;
		margin: 0 50px;
		margin-bottom: 30px;

		.bottom-title {
			font-size: 16px;
			font-weight: 600;
			color: #7265b8;
			width: fit-content;
			border-bottom: 4px solid #7265b8;
		}

		.content-div {
			.content-row {
				text-align: right;
				font-size: 14px;
				line-height: 1.5;
				color: #7c8595;
				display: block;
			}

			.content-val {
				display: list-item;
				text-align: right;
				font-size: 14px;
				line-height: 1.5;
				color: #7c8595;
				margin: 0;
			}
		}
	}

	.bottom-header {
		padding: 20px 0;
		background-color: #fff;
		text-align: center;
		background-color: #7265b8;

		.navigator {
			color: #fff;
		}

		.rights-text {
			color: #fff;
			font-size: 14px;
			margin-top: 5px;
			direction: rtl;
		}
	}
}

@media only screen and (min-width: 767px) {
	.page-content {
		.our-work-bg {
			.our-work {
				.work-services {
					.service-button {
						display: none;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.page-content {
		.home-header-bg {
			.home-header {
				display: block;

				.left {
					width: 90%;
					border: none;
					margin: 0 auto;
				}

				.right {
					width: calc(100% - 30px);
					margin: 0 15px;
					margin-bottom: 30px;
				}
			}
		}

		.content-wrapper {
			flex-direction: column;
			.fill-data {
				min-width: 0;
				.seprated-div {
					flex-wrap: wrap;
				}
			}
		}

		.our-work-bg {
			.our-work {
				.work-services {
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;

					.service {
						max-width: 100%;
					}
				}
			}
		}

		.footer {
			display: block;
			width: 98%;

			.left {
				width: 100%;
			}

			.right {
				width: 100%;
			}
		}
	}
}
