.header {
	display: flex;
	padding: 0px 50px;
	justify-content: space-between;
	align-items: center;
	height: fit-content;
	margin-bottom: 10px;
	background-color: #7265b8;
	border-bottom: 1px solid rgba(99, 99, 99, 0.2);
	box-shadow: 0 2px 8px 0 rgb(69 26 108 / 15%);

	.logo-first-line:hover {
		transform: scale(1.1);
	}

	.logo-first-line {
		line-height: 0.8;
		margin: 0;
		padding: 0;
		color: #fff;
		text-align: center;
		display: block;
		overflow: hidden;
		height: 25px;
		font-size: 20px;
		font-weight: 600;
		animation: SlideUp 1s ease-in-out;
		transition: 0.2s ease-in-out;
	}

	@keyframes SlideUp {
		from {
			height: 0;
		}
		to {
			height: 16px;
		}
	}

	.header-buttons {
		display: flex;
		align-items: center;
		height: 100%;

		.header-button {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 20px;
			text-decoration: none;
			direction: rtl;
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			border-top: 3px solid transparent;
			transition: 0.2s ease-in-out;
			margin: 0px 5px;

			.button-text {
				margin-left: 5px;
			}
		}

		.header-button:hover,
		.header-button.active {
			border-top: 3px solid #fff;
		}
	}

	.routes.mobile {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.header {
		padding: 5px 10px;

		.header-buttons {
			display: none;
		}

		.routes.mobile {
			flex: 1;
			display: flex;
			align-items: center;
			direction: rtl;
			height: 50px;

			.menu-button {
				background-color: transparent;
				outline: transparent;
				border: 1px solid white;
				width: 35px;
				height: 35px;
				border-radius: 5px;
				color: #fff;
				font-size: x-large;
				justify-content: center;
				display: flex;
				align-items: center;
			}

			.routes-menu {
				position: fixed;
				background-color: rgba(0, 0, 0, 0.9);
				width: 100%;
				height: 100%;
				z-index: 1000;
				left: 0;
				top: 0;
				width: 0px;
				overflow: hidden;
				transition: 0.2s ease-in-out;

				.close-button {
					float: left;
					margin: 5px 15px;
					font-size: xx-large;
					padding: 0px;
					background-color: transparent;
					border: none;
					color: #fff;
					outline: none;
				}

				.menu-title {
					margin-right: 20px;
					font-weight: 300;
					font-size: 1.5rem;
					margin-top: 15px;
					color: #fff;
					direction: rtl;
				}

				.menu-title::after {
					height: 1px;
					content: '';
					background: white;
					display: block;
					width: 80px;
				}

				.nav-route {
					display: flex;
					align-items: center;
					width: 100%;
					font-size: x-large;
					direction: rtl;
					color: #fff;
					text-decoration: none;
					border-bottom: 1px solid #444257;
					padding: 10px 20px;
					box-sizing: border-box;

					i {
						margin-left: 10px;
						font-size: 16px;
					}
				}
			}

			.routes-menu.show {
				width: 100%;
			}
		}
	}
}
